<template>
	<main
		class="container-white"
		:class="{'mobile-container': mobileCheck()}"
	>
		<section class="container-base">
			<div id="romb-container"></div>
			<div class="romb-page">
				<BlockPreloader
					class="romb-page__preloader"
					v-if="!loaded"
				/>
			</div>
		</section>
	</main>
</template>

<script>
import BlockPreloader from "../components/ui/BlockPreloader.vue";
import qs from "qs";
import axios from "axios";
import {mapGetters} from "vuex";
import {mobileCheck} from "@/helpers";

export default {
	name: "RombPage",
	components:
		{
			BlockPreloader,
		},
	data()
	{
		return {
			loaded: false,
			// при оплате заказа в виджет необходимо передавать ID заказа
			orderId: 0,
			// JWT token для авторизации в виджете по данным из лк
			authJWT: "",
			order:
				{
					merchantOrderId: 0,
					amount: 0,
					currency: "RUB",
					products: [],
				},
		};
	},
	computed:
		{
			...mapGetters({
				user: "getUser",
				merchant: "getPaymentInfo",
			}),
			merchantId()
			{
				return this.merchant.paymentInfo.merchantId;
			},
			merchantKey()
			{
				return this.merchant.paymentInfo.merchantKey;
			},
		},
	async mounted()
	{
		await this.$store.dispatch("getPaymentInfo");
		await this.getAuthJWT();
		await this.getProductsData();
		await this.getAmountData();
		await this.getProductsParameters();

		this.rombInit();
	},
	methods:
		{
			mobileCheck,
			async getAuthJWT()
			{
				if (this.user)
				{
					const response = await axios.get("/api/auth/getPaymentToken");
					this.authJWT = response.data;
				} else
				{
					// токен демо-пользователя
					this.authJWT =
						"eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2Nzc2ODY5NDQsImV4cCI6MTcwOTIyMjk0NCwicGhvbmUiOiIrNzk1MzY1NDg3OTgiLCJuYW1lIjoiVGVzdE5hbWUiLCJlbWFpbCI6InRlc3RAbmFtZS5jb20ifQ.G6cfh5skOQMMVF8Hlryu5h9gScs2Iu_6iHP-BfH_L3A";
				}
			},
			async getProductsData()
			{
				let topayData = this.$cookie.get("topay");

				this.orderId = JSON.parse(topayData).orderId;


				let data = qs.stringify({
					orderId: this.orderId || topayData,
				});

				const response = await axios.post("/api/personal/detailOrder", data);
				if (!response.data.success)
					return

				let products = response.data.products.filter((item) => item.id);

				for (let item of products)
				{
					this.order.products.push(
						{
							label: item.name,
							price: +item.price,
							amount: +item.price * item.count,
							quantity: item.count,
							vat: '',
							sno: '',
							paymentMethod: '',
							paymentObject: '',
							measurementUnit: "QT",
						})
				}
			},
			getAmountData()
			{
				this.order.amount = this.order.products.reduce((acc, item) => acc += item.amount, 0);
			},
			async getProductsParameters()
			{
				let productsParams = [];

				let topayData = this.$cookie.get("topay");
				await fetch(`/personal/order/payment/?AJAX=1&ORDER_ID=${this.orderId || topayData}`)
					.then(response => response.json())
					.then(data => productsParams = data)

				this.order.products.forEach((product) =>
				{
					let receipt_items = JSON.parse(productsParams.receipt_items);
					let params = receipt_items.find((item) => item.name == product.label);
					product.vat = params.vat;
					product.sno = params.sno;
					product.paymentMethod = params.payment_method;
					product.paymentObject = params.payment_object;
				})
				this.order.merchantOrderId = productsParams.order_id;
				this.providerParams = {
					callbackUrl: productsParams.callback_url,
					cancelUrl: productsParams.cancel_url,
					description: productsParams.description
				};
			},
			rombInit()
			{
				CheckoutWidget.init({
					container: document.getElementById("romb-container"),
					data:
						{
							order: this.order,
							merchantId: this.merchantId,
							merchantKey: this.merchantKey,
							externalJwt: this.authJWT,
							providerParams: this.providerParams
						},
				});
				this.loaded = true;
			},
		},
};
</script>
<style lang="scss">
.romb-page
{
	.romb-page__preloader
	{
		height: 440px;
		border-radius: 24px;
	}
}
</style>
